import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/Seo'
import PriceListTemplate from '../components/PriceList/PriceListTemplate'

const SePage = () => {
  const data = useStaticQuery(graphql`
    {
      allWpProduct(
        filter: { productDetails: { priceSe: { gt: 0 } } }
        sort: { fields: menuOrder, order: ASC }
      ) {
        nodes {
          brands {
            nodes {
              termTaxonomyId
            }
          }
          databaseId
          productDetails {
            year
            sku
            price: priceSe
            origin
            literbottle
            alcoholcontent
            bottlebox
            vat
            incvat
            invoiceno
            epdno
          }
          title
          menuOrder
        }
      }
      wp {
        globalSettings {
          dates {
            sweden {
              startdate
              enddate
            }
          }
          markets {
            markets {
              lang {
                alcoholcontent
                allbrand
                bottlebox
                contact
                downloadpdf
                literbottle
                origin
                price
                sku
                vat
                year
                title
              }
            }
          }
          password {
            passwordSe
          }
        }
      }
      file(name: { eq: "MH-LOGO-SVERIGE-gray-on-white-RGB-01" }) {
        id
        publicURL
      }
    }
  `)

  const { nodes } = data.allWpProduct
  const lang = data.wp.globalSettings.markets.markets
  const { passwordSe } = data.wp.globalSettings.password

  console.log(data.wp.globalSettings.dates.sweden)

  return (
    <>
      <SEO title="Sweden" />
      <PriceListTemplate
        logo={data.file.publicURL}
        market="se"
        datelang="sv"
        dates={data.wp.globalSettings.dates.sweden}
        marketName="Sweden"
        products={nodes}
        language={lang[2].lang}
        password={passwordSe}
      />
    </>
  )
}

export default SePage
